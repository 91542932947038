import { useContext, useEffect, useRef } from 'react';

import { RequestsContext } from '../contexts/requestsContext';
import useAsyncRequest from './useAsyncRequest';

const useGlobalContent = (path = 'index') => {
  const content = useRef({});
  const [performRequest] = useContext(RequestsContext);
  const [fetchContent, fetchState] = useAsyncRequest(
    () =>
      performRequest({
        method: 'GET',
        url: `/data/global/${path}.json`,
      }),
    (newContent) => {
      if (
        path === 'index' &&
        process.env.REACT_APP_RELEASE_SPACE !== 'meta' &&
        process.env.REACT_APP_RELEASE_SPACE !== 'meta-landing'
      ) {
        content.current = {
          ...newContent,
          sotLinks: newContent.sotLinks.filter(
            (link) => link.title !== 'The Conspiracy Test',
          ),
        };
      } else {
        content.current = newContent;
      }
    },
  );

  useEffect(() => {
    if (
      window.location.protocol === 'http:' &&
      window.location.host === 'theconspiracytest.org'
    ) {
      window.location.replace('https://theconspiracytest.org');
      return;
    }
    fetchContent();
  }, []);

  return {
    content: content.current,
    contentState: fetchState,
  };
};

export default useGlobalContent;
